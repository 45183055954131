var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", [
        _vm.stagelineConfig?.show_company_name
          ? _c(
              "h1",
              { staticClass: "mt-0", staticStyle: { "font-weight": "800" } },
              [_vm._v("\n    " + _vm._s(_vm.company.name) + "\n  ")]
            )
          : _vm._e(),
        _vm.stagelineConfig?.show_progress_bar
          ? _c(
              "div",
              { staticClass: "mb-5" },
              [
                _c("h6", [
                  _vm._v(
                    "\n      " + _vm._s(_vm.currentPeriod.title) + "\n    "
                  ),
                ]),
                _c("stageline-progress-bar"),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }